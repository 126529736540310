<template>
  <pool title="职称情况" @chooseCount="chooseCount" :dataList="showDataList" :countList="countList" v-loading="loading"></pool>
</template>

<script>
import {
  loadDetail,
  detailFilter,
  getCountData
} from './lib'

import pool from './pool'
export default {
  data () {
    return {
      dataList: [],
      searchParm: {
        jobLevel: null
      },
      loading: false
    }
  },
  components: {
    pool
  },
  computed: {
    typeList () {
      return [{value: '员级', label: '员级'}, {value: '助理级', label: '助理级'}, {value: '中级', label: '中级'}, {value: '副高级', label: '副高级'}, {value: '正高级', label: '正高级'}].map(v => {
        return {
          name: v.label
        }
      })
    },
    showDataList () {
      return this.dataList.filter(v => detailFilter(v, this.searchParm))
    },
    countList () {
      let data = getCountData(this.dataList, this.typeList, 'jobLevel', '无职称')
      data.forEach(v => v.active = this.searchParm.jobLevel === v.key)
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    chooseCount (cIndex) {
      let jobLevel = this.countList[cIndex].key
      if (this.searchParm.jobLevel === jobLevel) {
        this.searchParm.jobLevel = null
      } else {
        this.searchParm.jobLevel = jobLevel
      }
    },
    async loadData () {
      this.loading = true
      this.dataList = await loadDetail(this)
      this.loading = false
    }
  }
}
</script>
